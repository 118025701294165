/* debug-twcss/styles/global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

input {
  --tw-ring-shadow: 0 0 !important;
}

.required::before {
  content: '*';
  margin-right: 5px;
}